import React from "react";
import Layout from "../../components/general/Layout";
import WavesAndRain from "../../components/examples/waves-and-rain";
import { Helmet } from "react-helmet";
import {
    EXPERIENCES,
    markExperienceAsVisited
} from "../../components/general/store";

const Waves_Rain = () => {
    markExperienceAsVisited(EXPERIENCES.WAVES_AND_RAIN.key);
    return (
        <Layout>
            <Helmet>
                <script src="https://unpkg.com/aframe-particle-system-component@1.0.x/dist/aframe-particle-system-component.min.js"></script>
                <script src="https://unpkg.com/aframe-extras.ocean@%5E3.5.x/dist/aframe-extras.ocean.min.js"></script>
                <script src="https://unpkg.com/aframe-gradient-sky@1.0.4/dist/gradientsky.min.js"></script>
            </Helmet>
            <WavesAndRain />
        </Layout>
    );
};

export default Waves_Rain;
